@import 'src/styles/media-breakpoint';
@import 'src/styles/palette';

.patient-dashboard {
  &__title {
    color: color(foreground, light);
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }

  &__dials {
    display: grid;
    gap: 16px;
    grid-auto-rows: 250px;
    grid-template-columns: repeat(5, 1fr);
  }
  &__legend {
    border: 1px solid blue;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
  &__moreinfo {
    align-items: center;
    background-color: white;
    display: flex;
    font-style: Comfortaa;
    grid-column-end: 5;
    grid-column-start: 5;
    grid-row-end: 5;
    grid-row-start: 1;
    justify-content: center;
    text-align: center;
  }
  &__title2 {
    color: #242a2d;
    margin-bottom: 16px;
  }
}

.spaced {
  & > :not(:first-child) {
    margin-top: 1rem;
  }
}

@include media-breakpoint(gt-md) {
  .patient-dashboard {
    &__dials {
      grid-template-columns: repeat(5, 1fr);
    }
  }
}

@media print {
  /**
   * https://developer.mozilla.org/en-US/docs/Web/CSS/@page
   */
  @page {
    /* margin box at top right showing page number */
    @top-right {
      content: 'Page ' counter(page) '/' counter(pages);
    }

    margin-top: 1rem;
  }

  .patient-dashboard {
    background: white;

    &-cardcontainer {
      display: block;

      > :not(:last-child) {
        margin-bottom: 1rem;
      }

      > .elevated-card {
        box-shadow: none !important;
      }
    }

    .brainfunction-category {
      // Try to keep the all the content on one page
      page-break-inside: avoid;

      &.print-big {
        // 2rem is a magic number to push the print-big content down
        padding-top: 2rem;
      }
    }

    .brainfunction-measure {
      .brainfunction-measure-col {
        max-width: 50%;
        .ant-typography {
          font-size: 0.8rem;
        }
      }
    }
  }
}
