@import 'src/styles/variables';

/*override*/
.ant-collapse-header {
  font-size: 20px;
}
.ant-collapse-content {
  font-size: 16px;
}

.exitOnConfirmation {
  &__title {
    font-family: $basefont;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 0em;
    line-height: 50px;
    margin-bottom: 12px;
    text-align: center;
  }
  &__description {
    color: #242425;
    font-family: $basefont;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0em;
    line-height: 23px;
    margin-bottom: 40px;
    stylename: Body/Normal;
    text-align: center;
  }

  &__discard {
    background-color: $danger;
  }
}

.saveAndExitReminder {
  &__title {
    font-family: $basefont;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 0em;
    line-height: 50px;
    text-align: center;
  }
  &__description {
    font-family: $basefont;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0em;
    line-height: 23px;
    margin-bottom: 40px;
    text-align: center;
  }
  &__image {
    height: 200px;
    margin-bottom: 40px;
    width: 300px;
  }
  &__time {
  }
  &__remaining {
    font-family: $basefont;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0em;
    line-height: 23px;
    text-align: center;
  }
  &__duration {
    font-family: $basefont;
    font-size: 26px;
    font-weight: 600;
    letter-spacing: 0em;
    line-height: 31px;
    margin-bottom: 40px;
    text-align: center;
  }
}
